<template>
  <div class="mapbox-gl-control risk-control"
       :style="[offsetStyle, posStyle]">
    <v-menu v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            :left="attachLeft"
            offset-x
            z-index="10">
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn class="pa-1 ma-2"
               height="auto"
               min-width="0"
               :color="selectedType ? 'primary darken-1' : 'white'"
               small
               v-on="{ ...tooltip, ...menu }"
               @click="buttonClick">
              <v-icon>warning</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Risks</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined
              flat
              width="16rem"
              :loading="!riskDataLoaded.riskOptions">
        <v-card-text>
          <slot />
          <!-- <v-layout :style="layoutStyle"> -->
          <v-layout column>
            <v-radio-group
              v-model="selectedType"
              @change="changedRiskType"
              hide-details
              class="mt-0"
            >
              <v-radio v-for="option in riskTypeOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
                color="primary darken-1"
              />
            </v-radio-group>
            <v-autocomplete v-if="selectedType && hasSourceOptions"
                            v-model="selectedSource"
                            :items="sourceOptions"
                            @change="changedRiskSource"
                            color="primary darken-1"
                            item-color="primary darken-1"
                            dense
                            hide-details
                            placeholder="None selected"
                            :open-on-clear="false">
            </v-autocomplete>
          </v-layout>
        </v-card-text>
      </v-card>

    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'pibot-mapbox-risk-toggle-pof-cof',
  mixins: [positionStyleMixin, offsetStyleMixin, mapboxBasicsMixin, mapboxLazyMixin],
  data () {
    return {
      menu: false,
      selectedType: 0,
      sourceOptions: [],
      selectedSource: undefined
    }
  },
  computed: {
    ...mapState({
      riskTypeOptions: state => state.risks.riskTypes,
      riskSourceOptions: state => state.risks.riskSources,
      riskDataLoaded: state => state.risks.loaded
    }),
    ...mapGetters({
      riskPofCofOptions: 'mapbox/riskPofCofOptions'
    }),
    hasSourceOptions () {
      return this.selectedType && Object.keys(this.riskSourceOptions).includes(this.selectedType)
    }
  },
  methods: {
    ...mapActions({
      fetchAssets: 'risks/socket_fetchRiskData'
    }),
    ...mapMutations({
      setSelectedRiskType: 'mapbox/setSelectedRiskType',
      setSelectedRiskSource: 'mapbox/setSelectedRiskSource'
    }),
    buttonClick () {
      if (this.riskDataLoaded.riskOptions === false) this.fetchAssets()
    },
    changedRiskType () {
      if (this.selectedType) {
        this.sourceOptions = this.riskSourceOptions[this.selectedType]
        this.selectedSource = this.riskSourceOptions[this.selectedType][0].value
      }
      this.processSelectedFeature(this.selectedType, this.selectedSource)
      this.setSelectedRiskType(this.selectedType === 0 ? null : this.selectedType)
    },
    changedRiskSource () {
      this.processSelectedFeature(this.selectedType, this.selectedSource)
      this.setSelectedRiskSource(this.selectedSource)
    },
    processSelectedFeature (type, source) {
      for (const option of this.riskPofCofOptions) {
        const willBeVisible = option.value === type

        if (!willBeVisible) { // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }
}
</script>
